import {version} from "./version";
// @ts-ignore
import txt from "raw-loader!./build.txt";

export const environment = {
  production: true,
  API_URL: "https://api.educ.space",
  VERSION: version,
  BUILD: txt,
  MODE: "production"
};
