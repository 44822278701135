import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {AccessManageComponent} from "./access-manage/access-manage.component";
import {EdsPersonModule} from "../person/person.module";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbTypeaheadModule,
    BsacModelListModule,
    BsacFormsModule,
    BsacApiConstantsModule,
    EdsPersonModule
  ],
  declarations: [AccessManageComponent],
  exports: [AccessManageComponent]
})
export class EdsAccessModule {
}
