import {NewsMessageCreateComponent} from "./newsmessage-create/newsmessage-create.component";
import {NewsMessageDeleteComponent} from "./newsmessage-delete/newsmessage-delete.component";
import {NewsMessageListComponent} from "./newsmessage-list/newsmessage-list.component";
import {NewsMessageManageComponent} from "./newsmessage-manage/newsmessage-manage.component";
import {NewsMessageSelectModule} from "./newsmessage-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {EdsAccessModule} from "../../people/access/access.module";

@NgModule({
  imports: [
    NewsMessageSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    EdsAccessModule
  ],
  providers: [],
  declarations: [
    NewsMessageCreateComponent,
    NewsMessageListComponent,
    NewsMessageDeleteComponent,
    NewsMessageManageComponent
  ],
  exports: [
    NewsMessageCreateComponent,
    NewsMessageListComponent,
    NewsMessageDeleteComponent,
    NewsMessageManageComponent,
    NewsMessageSelectModule
  ]
})
export class NewsMessageModule {
}
