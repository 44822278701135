import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {NewsCategSelectComponent} from "./newscateg-select/newscateg-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [NewsCategSelectComponent],
  exports: [NewsCategSelectComponent]
})
export class NewsCategSelectModule {}
