import {ReferenceCreateComponent} from "./reference-create/reference-create.component";
import {ReferenceDeleteComponent} from "./reference-delete/reference-delete.component";
import {ReferenceListComponent} from "./reference-list/reference-list.component";
import {ReferenceManageComponent} from "./reference-manage/reference-manage.component";
import {ReferenceSelectModule} from "./reference-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {ReferenceFormComponent} from "./reference-form/reference-form.component";

@NgModule({
  imports: [
    ReferenceSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [
    ReferenceCreateComponent,
    ReferenceListComponent,
    ReferenceDeleteComponent,
    ReferenceManageComponent,
    ReferenceFormComponent],
  exports: [
    ReferenceCreateComponent,
    ReferenceListComponent,
    ReferenceDeleteComponent,
    ReferenceManageComponent,
    ReferenceSelectModule,
    ReferenceFormComponent
  ]
})
export class ReferenceModule {
}
