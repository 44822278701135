import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthSiteGuard implements CanActivate,
  CanActivateChild {

  constructor(public _auth: AuthService,
              public _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    return true;
  }


  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log("In auth guard");
    return this._auth.autologin(true).pipe(
      map(() => {
        return true;
      }));
  }
}

