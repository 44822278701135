import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../auth.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";

@Component({
  selector: "eds-login",
  templateUrl: "./login.component.pug",
  styleUrls: ["./login.component.sass"]
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public errorMessage: string = null;

  constructor(private _msg: BsacMessageService,
              private _auth: AuthService,
              private _fb: FormBuilder,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      username: ["", [Validators.required, Validators.minLength(4)]],
      password: ["", [Validators.required, Validators.minLength(6)]]
    });
  }

  public login(): void {
    if (this.form.valid) {
      this._auth.userPassLogin(this.form.value.username, this.form.value.password)
        .subscribe((result) => {
          if (result.authenticated === true) {
            this._auth.getRedirectUrl().subscribe((url) => {
              this._msg.success("Identification réussie...");
              this.router.navigateByUrl(url);
            });
          } else {
            this.errorMessage = result.message;
            this._msg.warning(result.message);
          }
        });
    }
  }
}
