import {Component, HostListener, OnInit} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {AuthService} from "educspace";
import {animate, state, style, transition, trigger} from "@angular/animations";

export const RESPONSIVE_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

@Component({
  selector: "edsm-main",
  templateUrl: "./main.component.pug",
  styleUrls: ["./main.component.sass"],
  animations: [
    trigger("collapse", [
      state("open", style({
        opacity: "1"
      })),
      state("closed", style({
        opacity: "0",
        display: "none"
      })),
      transition("closed => open", animate("400ms ease-in")),
      transition("open => closed", animate("100ms ease-out"))
    ])
  ]
})
export class MainComponent implements OnInit {

  public version = environment.VERSION;
  public production = (environment.MODE === "production");
  public build = environment.BUILD;
  public isNavbarCollapsed = true;
  private _innerWidth: number;

  constructor(public auth: AuthService) {
  }

  private _isNavbarCollapsedAnim = "closed";

  public get isNavbarCollapsedAnim(): string {
    return this._isNavbarCollapsedAnim;
  }

  public ngOnInit(): void {
    console.log("We are in admin zone");
    this.onResize(window);
  }

  @HostListener("window:resize", ["$event.target"])
  onResize(event) {
    this._innerWidth = event.innerWidth;
    if (event.innerWidth > RESPONSIVE_BREAKPOINTS.md) {
      this._isNavbarCollapsedAnim = "open";
      this.isNavbarCollapsed = true;
    } else {
      this._isNavbarCollapsedAnim = "closed";
    }
  }

  public toggleNavbar(): void {
    if (this._innerWidth > RESPONSIVE_BREAKPOINTS.md) {
      this._isNavbarCollapsedAnim = "open";
      this.isNavbarCollapsed = true;
    } else {
      if (this.isNavbarCollapsed) {
        this._isNavbarCollapsedAnim = "open";
        this.isNavbarCollapsed = false;
      } else {
        this._isNavbarCollapsedAnim = "closed";
        this.isNavbarCollapsed = true;
      }
    }
  }
}
