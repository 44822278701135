import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {KioskFamilySelectComponent} from "./kioskfamily-select/kioskfamily-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [KioskFamilySelectComponent],
  exports: [KioskFamilySelectComponent]
})
export class KioskFamilySelectModule {}
