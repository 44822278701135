import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {AuthService} from "./auth.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";

@Injectable({
  providedIn: "root"
})
export class AuthValidGuard implements CanActivate {

  constructor(private _auth: AuthService, private _msg: BsacMessageService, private _router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log("Checking can activate", this._auth);
    return this.checkAuthentication(state.url);
  }

  public canActivateChild(route: ActivatedRouteSnapshot,
                          state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log("Checking can activate child");
    return this.canActivate(route, state);
  }


  public checkAuthentication(url: string): Observable<boolean> {
    if (!this._auth.isAuthenticated) {
      console.log("Authentication NOT OK", this._auth);
      this._auth.redirectUrl = url;
      // FIXME: parametrize login url
      this._router.navigate(["/login"]);
      return of(false);
    } else {
      console.log("Authentication OK", this._auth);
      return of(true);
    }
  }
}
