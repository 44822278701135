import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {
  DS_REST_BACKEND_AUTHENTICATOR,
  DS_REST_BACKEND_CONFIG,
  DSRestBackend,
  DSRestModule,
  DSTokenAuthentication
} from "@solidev/ngdataservice";
import {AppComponent} from "./app.component";
import {RouterModule} from "@angular/router";
import {LOCAL_STORAGE_OBJECT} from "educspace";
import {BsacMessageModule} from "@solidev/bsadmincomponents";
import {EnscpCommonModule} from "./views/common/common.module";
import {appRoutes} from "./app.routing";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../environments/environment";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      paramsInheritanceStrategy: "always",
      useHash: false,
      relativeLinkResolution: "legacy"
    }),
    DSRestModule,
    EnscpCommonModule,
    BsacMessageModule,
    NgbModule
  ],
  providers: [
    DSRestBackend,
    {provide: DS_REST_BACKEND_CONFIG, useValue: {url: environment.API_URL}},
    {
      provide: DS_REST_BACKEND_AUTHENTICATOR,
      useClass: DSTokenAuthentication
    },
    {
      provide: LOCAL_STORAGE_OBJECT,
      useValue: localStorage
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
