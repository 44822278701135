import {KioskFamily, KioskFamilyService} from "./kioskfamily.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class KioskFamilyResolve implements Resolve<KioskFamily> {
  constructor(private _model: KioskFamilyService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<KioskFamily> {
    return this._model.get(+route.params["kioskfamilyId"]);
  }
}
