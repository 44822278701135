import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HomeComponent} from "./home/home.component";
import {RouterModule} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {EdsAuthModule} from "educspace";
import {PubHomeComponent} from "./pub-home/pub-home.component";
import {MainComponent} from "./main/main.component";
import {BsacDevModule, BsacMessageModule} from "@solidev/bsadmincomponents";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {LogoutComponent} from "./logout/logout.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    EdsAuthModule,
    BsacDevModule,
    BsacMessageModule,
    NgbModule
  ],
  declarations: [HomeComponent, LoginComponent, LogoutComponent, PubHomeComponent, MainComponent],
  exports: [HomeComponent, LoginComponent, LogoutComponent, PubHomeComponent, MainComponent]
})
export class EnscpCommonModule {
}
