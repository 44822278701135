import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PersonListComponent} from "./person-list/person-list.component";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PersonFormComponent} from "./person-form/person-form.component";
import {PersonSelectComponent} from "./person-select/person-select.component";
import {NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbTypeaheadModule,
    BsacModelListModule,
    BsacFormsModule,
    BsacApiConstantsModule
  ],
  declarations: [PersonListComponent, PersonFormComponent, PersonSelectComponent],
  exports: [PersonListComponent, PersonFormComponent, PersonSelectComponent]
})
export class EdsPersonModule {
}
