import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {GroupListComponent} from "./group-list/group-list.component";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GroupFormComponent} from "./group-form/group-form.component";
import {GroupSelectComponent} from "./group-select/group-select.component";
import {NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbTypeaheadModule,
    BsacModelListModule,
    BsacFormsModule,
    BsacApiConstantsModule
  ],
  declarations: [GroupListComponent, GroupFormComponent, GroupSelectComponent],
  exports: [GroupListComponent, GroupFormComponent, GroupSelectComponent]
})
export class EdsGroupModule {
}
