import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class KioskFamily extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "school",
    verboseName: "School",
    required: false,
    helpText: "",
    choices: []
  })
  public school: number;

  @DSDefaultField({
    name: "school_details",
    verboseName: "Etablissement",
    required: false,
    helpText: "",
    choices: []
  })
  public school_details: any;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 200
  })
  public name: string;

  @DSManyToManyField({
    name: "owners",
    verboseName: "IDs Responsables",
    required: false,
    helpText: "",
    choices: []
  })
  public owners: number[];

  @DSDefaultField({
    name: "owners_details",
    verboseName: "Responsables",
    required: false,
    helpText: "",
    choices: []
  })
  public owners_details: any;

  @DSManyToManyField({
    name: "editors",
    verboseName: "IDs Editeurs",
    required: false,
    helpText: "",
    choices: []
  })
  public editors: number[];

  @DSDefaultField({
    name: "editors_details",
    verboseName: "Editeurs",
    required: false,
    helpText: "",
    choices: []
  })
  public editors_details: any;

  @DSManyToManyField({
    name: "reader",
    verboseName: "IDs Lecteurs",
    required: false,
    helpText: "",
    choices: []
  })
  public reader: number[];

  @DSDefaultField({
    name: "readers_details",
    verboseName: "Lecteurs",
    required: false,
    helpText: "",
    choices: []
  })
  public readers_details: any;

  @DSBooleanField({
    name: "public",
    verboseName: "Public ?",
    required: false,
    helpText: "",
    choices: []
  })
  public public: boolean;

  @DSDefaultField({
    name: "tags",
    verboseName: "Tags",
    required: false,
    helpText: "",
    choices: []
  })
  public tags: string[];

  @DSDefaultField({
    name: "extras",
    verboseName: "Extra data",
    required: false,
    helpText: "",
    choices: []
  })
  public extras: string[];

  @DSTextField({
    name: "description_src",
    verboseName: "Description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 20000
  })
  public description_src: string;

  @DSTextField({
    name: "description",
    verboseName: "Description (html)",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 20000
  })
  public description: string;

  @DSCharField({
    name: "description_type",
    verboseName: "Format description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 4
  })
  public description_type: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: true,
    helpText: "",
    choices: [["OK", "OK"], ["PN", "Pending validation"], ["NOK", "Non valide"]],
    maxLength: 4
  })
  public status: string;

  @DSCharField({
    name: "importid",
    verboseName: "ID origine",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 200
  })
  public importid: string;
}

@Injectable({
  providedIn: "root"
})
export class KioskFamilyService extends DSRestCollection<KioskFamily> {
  public adapter_config = {basePath: "/api/v1/kioskfamilies"};
  public model = KioskFamily;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
