import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class KioskClient extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 200
  })
  public name: string;

  @DSCharField({
    name: "mac",
    verboseName: "Mac address",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 20
  })
  public mac: string;

  @DSCharField({
    name: "code",
    verboseName: "Client code",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 128
  })
  public code: string;

  @DSCharField({
    name: "key",
    verboseName: "Client key",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 128
  })
  public key: string;

  @DSManyToManyField({
    name: "families",
    verboseName: "Familles",
    required: false,
    helpText: "",
    choices: []
  })
  public families: number[];

  @DSDefaultField({
    name: "tags",
    verboseName: "Tags",
    required: false,
    helpText: "",
    choices: []
  })
  public tags: string[];

  @DSDefaultField({
    name: "extras",
    verboseName: "Extra data",
    required: false,
    helpText: "",
    choices: []
  })
  public extras: string[];

  @DSTextField({
    name: "description_src",
    verboseName: "Description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 20000
  })
  public description_src: string;

  @DSTextField({
    name: "description",
    verboseName: "Description (html)",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 20000
  })
  public description: string;

  @DSCharField({
    name: "description_type",
    verboseName: "Format description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 4
  })
  public description_type: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: true,
    helpText: "",
    choices: [["OK", "OK"], ["PN", "Pending validation"], ["NOK", "Non valide"]],
    maxLength: 4
  })
  public status: string;

  @DSCharField({
    name: "importid",
    verboseName: "ID origine",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 200
  })
  public importid: string;
}

@Injectable({
  providedIn: "root"
})
export class KioskClientService extends DSRestCollection<KioskClient> {
  public adapter_config = {basePath: "/api/v1/kioskclients"};
  public model = KioskClient;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
