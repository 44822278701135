import {Component, OnInit} from "@angular/core";

@Component({
  selector: "edsm-login",
  templateUrl: "./login.component.pug",
  styleUrls: ["./login.component.sass"]
})
export class LoginComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
