import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class LabExpItem extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "lab",
    verboseName: "Labo",
    required: false,
    helpText: "",
    choices: []
  })
  public lab: number;

  @DSForeignKeyField({
    name: "experiment",
    verboseName: "Expérience",
    required: false,
    helpText: "",
    choices: []
  })
  public experiment: number;

  @DSCharField({
    name: "itype",
    verboseName: "Type",
    required: false,
    helpText: "Type de matériel",
    minLength: 0,
    maxLength: 4,
    choices: [["ELV", "Eleve"], ["TEA", "Prof"], ["LAB", "Laboratoire"], ["RES", "Réserve"], ["OTH", "Autre"]]
  })
  public itype: string;

  @DSForeignKeyField({
    name: "itemref",
    verboseName: "Matériel",
    required: false,
    helpText: "Matériel",
    relatedModel: "labitem.LabItem",
    remoteField: "id",
    relatedName: "labexpitem_set"
  })
  public itemref: number;

  @DSCharField({
    name: "itemtitle",
    verboseName: "Matériel (texte)",
    required: false,
    helpText: "Matériel (texte)",
    minLength: 0,
    maxLength: 300,
    choices: []
  })
  public itemtitle: string;


  @DSDecimalField({
    name: "quantity",
    verboseName: "Quantité",
    required: false,
    helpText: "",
    choices: [],
    factor: 1000
  })
  public quantity: number;

  @DSForeignKeyField({
    name: "unit",
    verboseName: "Unité",
    required: false,
    helpText: "",
    choices: []
  })
  public unit: number;

  @DSDefaultField({
    name: "unit_details",
    verboseName: "Unité",
    required: false,
    helpText: "",
    choices: []
  })
  public unit_details: any;

  @DSCharField({
    name: "location",
    verboseName: "Emplacement",
    required: false,
    helpText: "Emplacement",
    minLength: 0,
    maxLength: 40,
    choices: []
  })
  public location: string;


  @DSTextField({
    name: "comments",
    verboseName: "Commentaires",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 1000
  })
  public comments: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    choices: [["OK", "OK"], ["DEP", "Plus à jour"], ["ERR", "Erroné"], ["ARC", "Archive"]],
    maxLength: 4
  })
  public status: string;

  @DSCharField({
    name: "importid",
    verboseName: "ID origine",
    required: false,
    helpText: "",
    choices: []
  })
  public importid: string;
}

@Injectable({
  providedIn: "root"
})
export class LabExpItemService extends DSRestCollection<LabExpItem> {
  public adapter_config = {basePath: "/api/v1/labs/${context.lab}/experiments/experiments/${context.experiment}/items"};
  public model = LabExpItem;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
