import {NewsCategCreateComponent} from "./newscateg-create/newscateg-create.component";
import {NewsCategDeleteComponent} from "./newscateg-delete/newscateg-delete.component";
import {NewsCategListComponent} from "./newscateg-list/newscateg-list.component";
import {NewsCategManageComponent} from "./newscateg-manage/newscateg-manage.component";
import {NewsCategSelectModule} from "./newscateg-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {EdsAccessModule} from "../../people/access/access.module";

@NgModule({
  imports: [
    NewsCategSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    EdsAccessModule
  ],
  providers: [],
  declarations: [NewsCategCreateComponent, NewsCategListComponent, NewsCategDeleteComponent, NewsCategManageComponent],
  exports: [
    NewsCategCreateComponent,
    NewsCategListComponent,
    NewsCategDeleteComponent,
    NewsCategManageComponent,
    NewsCategSelectModule
  ]
})
export class NewsCategModule {
}
