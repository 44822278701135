import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LabUserListComponent} from "./labuser-list/labuser-list.component";
import {CdkTableModule} from "@angular/cdk/table";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgPipesModule} from "ngx-pipes";
import {LabUserCreateComponent} from "./labuser-create/labuser-create.component";
import {LabuserBulkCreateComponent} from "./labuser-bulk-create/labuser-bulk-create.component";
import {LabuserManageComponent} from "./labuser-manage/labuser-manage.component";


@NgModule({
  declarations: [LabUserListComponent, LabUserCreateComponent, LabuserBulkCreateComponent, LabuserManageComponent],
  exports: [LabUserListComponent, LabUserCreateComponent, LabuserBulkCreateComponent, LabuserManageComponent],
  imports: [
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgPipesModule
  ]
})
export class LabUserModule {
}

