import {HomeComponent} from "./views/common/home/home.component";
import {LoginComponent} from "./views/common/login/login.component";
import {AuthSiteGuard, AuthValidGuard} from "educspace";
import {PubHomeComponent} from "./views/common/pub-home/pub-home.component";
import {MainComponent} from "./views/common/main/main.component";
import {LogoutComponent} from "./views/common/logout/logout.component";
import {Routes} from "@angular/router";

export const appRoutes: Routes = [
  {path: "", pathMatch: "full", component: PubHomeComponent},
  {path: "login", component: LoginComponent},
  {path: "logout", component: LogoutComponent},
  {
    path: "", canActivateChild: [AuthSiteGuard], component: MainComponent,
    children: [
      {path: "home", component: HomeComponent, canActivate: [AuthValidGuard]},

      {
        path: "", canActivateChild: [AuthValidGuard], children: [
          {
            path: "labs",
            loadChildren: () => import("./views/lab/lab.module").then(m => m.EdslLabModule)
          },
          {
            path: "kiosks",
            loadChildren: () => import("./views/kiosk/kiosk.module").then(m => m.EdsmKioskModule)
          },
          {
            path: "evals",
            loadChildren: () => import("./views/eval/eval.module").then(m => m.EdsmEvalModule)
          }
        ]
      }
    ]
  }

];
