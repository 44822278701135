import {Component, OnInit} from "@angular/core";

@Component({
  selector: "edsm-logout",
  templateUrl: "./logout.component.pug",
  styleUrls: ["./logout.component.sass"]
})
export class LogoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
