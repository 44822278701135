import {KioskClientCreateComponent} from "./kioskclient-create/kioskclient-create.component";
import {KioskClientDeleteComponent} from "./kioskclient-delete/kioskclient-delete.component";
import {KioskClientListComponent} from "./kioskclient-list/kioskclient-list.component";
import {KioskClientManageComponent} from "./kioskclient-manage/kioskclient-manage.component";
import {KioskClientSelectModule} from "./kioskclient-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {
  BsacApiConstantsModule,
  BsacFormsModule,
  BsacModelListModule,
} from "@solidev/bsadmincomponents";
import {EdsAccessModule} from "../../people/access/access.module";

@NgModule({
  imports: [
    KioskClientSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    EdsAccessModule
  ],
  providers: [],
  declarations: [
    KioskClientCreateComponent,
    KioskClientListComponent,
    KioskClientDeleteComponent,
    KioskClientManageComponent
  ],
  exports: [
    KioskClientCreateComponent,
    KioskClientListComponent,
    KioskClientDeleteComponent,
    KioskClientManageComponent,
    KioskClientSelectModule
  ]
})
export class KioskClientModule {}
