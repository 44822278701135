import {Component, OnInit} from "@angular/core";
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";

@Component({
  selector: "eds-logout",
  templateUrl: "./logout.component.pug",
  styleUrls: ["./logout.component.sass"]
})
export class LogoutComponent implements OnInit {

  constructor(private _auth: AuthService, public _router: Router) {
  }

  ngOnInit() {
  }

  public logout(): void {
    this._auth.logout();
    this._router.navigate(["/"]);
  }
}
