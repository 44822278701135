import {Component} from "@angular/core";

@Component({
  selector: "edsm-root",
  templateUrl: "./app.component.pug",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "lab";
}
