import {KioskFamilyCreateComponent} from "./kioskfamily-create/kioskfamily-create.component";
import {KioskFamilyDeleteComponent} from "./kioskfamily-delete/kioskfamily-delete.component";
import {KioskFamilyListComponent} from "./kioskfamily-list/kioskfamily-list.component";
import {KioskFamilyManageComponent} from "./kioskfamily-manage/kioskfamily-manage.component";
import {KioskFamilySelectModule} from "./kioskfamily-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {EdsAccessModule} from "../../people/access/access.module";

@NgModule({
  imports: [
    KioskFamilySelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    EdsAccessModule
  ],
  providers: [],
  declarations: [
    KioskFamilyCreateComponent,
    KioskFamilyListComponent,
    KioskFamilyDeleteComponent,
    KioskFamilyManageComponent
  ],
  exports: [
    KioskFamilyCreateComponent,
    KioskFamilyListComponent,
    KioskFamilyDeleteComponent,
    KioskFamilyManageComponent,
    KioskFamilySelectModule
  ]
})
export class KioskFamilyModule {
}
