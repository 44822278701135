import {KioskClient, KioskClientService} from "./kioskclient.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class KioskClientResolve implements Resolve<KioskClient> {
  constructor(private _model: KioskClientService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<KioskClient> {
    return this._model.get(+route.params["kioskclientId"]);
  }
}
