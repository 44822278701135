import {NewsMessage, NewsMessageService} from "./newsmessage.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class NewsMessageResolve implements Resolve<NewsMessage> {
  constructor(private _model: NewsMessageService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<NewsMessage> {
    return this._model.get(+route.params["newsmessageId"]);
  }
}
