import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  IDSModelClass
} from "@solidev/ngdataservice";


export const PHONE_TYPES = [
  ["HOME", "Home"],
  ["WORK", "Work"],
  ["PERMOBILE", "Personal mobile"],
  ["PROMOBILE", "Professional mobile"],
  ["PERFAX", "Personal fax"],
  ["PROFAX", "Professional fax"],
  ["PREF", "Preferred"]
];

export const EMAIL_TYPES = [
  ["HOLE", "Home"],
  ["WORK", "Work"],
  ["PAR", "Parent"],
  ["PRE", "Preferred"]
];

export const ADDRESSES_TYPES = [
  ["HOME", "Home"],
  ["WORK", "Work"]
];

export const PERSON_TYPE = [
  ["TEA", "Teacher"],
  ["STU", "Student"],
  ["EXE", "Executive"],
  ["RES", "Responsable"],
  ["EXT", "External"]
];

/**
 *

 """ Common fields to deal with real people data """
 username = models.CharField(verbose_name=_(u"Identifiant"), blank=False,
 max_length=200,
 db_index=True, unique=True)
 # Mandatory fields
 lastname = models.CharField(_(u"Nom de famille"), blank=False,
 max_length=200, db_index=True,
 help_text=_("Nom de famille, en majuscules"))
 firstname = models.CharField(_(u"Prénom"), blank=False, max_length=200,
 db_index=True)
 # Optional but recommended fields
 birthday = models.DateField(db_index=True, null=True, blank=True)
 firstname2 = models.CharField(max_length=200, default="", blank=True)
 firstname3 = models.CharField(max_length=200, default="", blank=True)
 firstname4 = models.CharField(max_length=200, default="", blank=True)
 uniquid = models.CharField(max_length=200, db_index=True, blank=True)
 # Other infos
 birthname = models.CharField(max_length=200, default="", blank=True)
 ptype = models.CharField(max_length=3, choices=PERSON_TYPE,
 verbose_name=_("User type"),
 help_text=_("Choose user type"),
 blank=True)
 fonction = models.CharField(max_length=40, blank=True)
 # Authentication
 initialpass = models.CharField(max_length=20, blank=True)
 firstlogin = models.BooleanField(default=False)
 # Logœ
 log = models.ForeignKey(Log, null=True, on_delete=models.CASCADE)
 is_staff = models.BooleanField(_("Is staff"), default=False,
 help_text=_("Is staff user"))
 is_active = models.BooleanField(_("Is user active"), default=True,
 help_text=_("Is user active"))

 */

export class Person extends DSModel {
  @DSAutoField({
    name: "id"
  })
  public id: number;

  @DSCharField({
    name: "lastname",
    required: true,
    minLength: 2,
    maxLength: 200
  })
  public lastname: string;

  @DSCharField({
    name: "lastname",
    maxLength: 200
  })
  public birthname: string;

  @DSCharField({
    name: "firstname",
    required: true,
    minLength: 2,
    maxLength: 200
  })
  public firstname: string;

  @DSDateField({
    name: "birthday"
  })
  public bidthday: Date;

  @DSCharField({
    name: "firstname2",
    maxLength: 200
  })
  public firstname2: string;

  @DSCharField({
    name: "firstname3",
    maxLength: 200
  })
  public firstname3: string;

  @DSCharField({
    name: "firstname4",
    maxLength: 200
  })
  public firstname4: string;

  @DSCharField({
    name: "ptype",
    maxLength: 200,
    choices: PERSON_TYPE,
    default: "STU"
  })
  public ptype: string;

  @DSCharField({
    name: "fonction",
    maxLength: 200
  })
  public fonction: string;
}

@Injectable({
  providedIn: "root"
})
export class PersonService extends DSRestCollection<Person> {
  public model: IDSModelClass<Person> = Person;
  public adapter_config: any = {basePath: "/api/v1/persons"};

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
