import {Component, OnInit} from "@angular/core";
import {Lab, LabService} from "educspace";

@Component({
  selector: "edsm-home",
  templateUrl: "./home.component.pug",
  styleUrls: ["./home.component.sass"]
})
export class HomeComponent implements OnInit {
  public labs: Lab[];

  constructor(private _labs: LabService) {
  }

  ngOnInit() {
    this._labs.queryset.all().subscribe((res) => {
      this.labs = res.items;
    });
  }

}
