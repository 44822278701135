import {Component, OnInit} from "@angular/core";
import {AuthService} from "educspace";
import {Router} from "@angular/router";

@Component({
  selector: "edsm-pub-home",
  templateUrl: "./pub-home.component.pug",
  styleUrls: ["./pub-home.component.sass"]
})
export class PubHomeComponent implements OnInit {
  public disp = false;

  constructor(private _auth: AuthService, public _router: Router) {
  }

  public async ngOnInit() {

    await this._auth.autologin().toPromise();
    if (this._auth.user) {
      this._router.navigate(["/home"]);
    } else {
      this.disp = true;
    }
  }

}
