import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSEmailField,
  DSModel,
  DSPasswordField,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export interface LabUserRoles {
  tech: boolean;
  user: boolean;
  student: boolean;
  resp: boolean;
}


@Injectable({
  providedIn: "root"
})
export class LabUser extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID"
  })
  public id: number;
  @DSCharField({
    name: "username",
    verboseName: "Login"
  })
  public username: string;

  // @DSBooleanField({
  //   name: "is_active",
  //   verboseName: "Actif ?"
  // })
  // public is_active: boolean;
  // @DSBooleanField({
  //   name: "is_superuser",
  //   verboseName: "Superuser ?"
  // })
  // public is_superuser: boolean;
  // @DSBooleanField({
  //   name: "is_staff",
  //   verboseName: "Staff ?"
  // })
  // public is_staff: boolean;
  @DSCharField({
    name: "firstname",
    verboseName: "Prénom"
  })
  public firstname: string;
  @DSCharField({
    name: "lastname",
    verboseName: "Nom de famille"
  })
  public lastname: string;
  @DSDateTimeField({
    name: "birthday",
    verboseName: "Date de naissance"
  })
  public birthday: Date;
  @DSEmailField({
    name: "useremail",
    verboseName: "Email",
    validateEmail: true
  })
  public useremail: string;
  @DSPasswordField({
    name: "initialpass",
    verboseName: "Mot de passe initial"
  })
  public initialpass: string;
  @DSDefaultField({
    name: "labroles",
    verboseName: "Rôles"
  })
  public labroles: LabUserRoles;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;


}


@Injectable({
  providedIn: "root"
})
export class LabUserService extends DSRestCollection<LabUser> {
  public adapter_config = {basePath: "/api/v1/labs/${context.lab}/users"};
  public model = LabUser;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
