import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class Reference extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 200
  })
  public name: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "",
    choices: [["PG", "Programme"], ["CS", "Personnel"]],
    maxLength: 3
  })
  public type: string;

  @DSTextField({
    name: "description_src",
    verboseName: "Description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 1000
  })
  public description_src: string;

  @DSTextField({
    name: "description",
    verboseName: "Description (HTML)",
    required: false,
    helpText: "",
    choices: []
  })
  public description: string;

  @DSDefaultField({
    name: "extra",
    verboseName: "Extra",
    required: false,
    helpText: "",
    choices: []
  })
  public extra: any;

  @DSForeignKeyField({
    name: "folder",
    verboseName: "Dossier",
    required: false,
    helpText: "",
    choices: []
  })
  public folder: number;

  @DSForeignKeyField({
    name: "owner",
    verboseName: "Créateur",
    required: false,
    helpText: "",
    choices: []
  })
  public owner: number;

  @DSBooleanField({
    name: "public",
    verboseName: "Public ?",
    required: false,
    helpText: "",
    choices: []
  })
  public public: boolean;

  @DSDefaultField({
    name: "tags",
    verboseName: "Tags",
    required: false,
    helpText: "",
    choices: []
  })
  public tags: string[];

  @DSDateTimeField({
    name: "created",
    verboseName: "Date création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date MAJ",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;
}

@Injectable({
  providedIn: "root"
})
export class ReferenceService extends DSRestCollection<Reference> {
  public adapter_config = {basePath: "/api/v1/references"};
  public model = Reference;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
