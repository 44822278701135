import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";
import {LabUser, LabUserService} from "./labuser.service";
import {AuthService} from "../../auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class LabUserResolve implements Resolve<LabUser> {
  constructor(private _model: LabUserService,
              private _auth: AuthService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<LabUser> {

    return this._model.get(+route.params["labuserId"],
      {context: {lab: +route.params["labId"]}});
  }
}
